import React from "react";
import product from "../Component/img/product.png";
import FooterMenu from '../Component/FooterMenu'; 
import Navbar from "../Component/Navbar";


function Shop() {
  const products = [
    { id: 1, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 2, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 3, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 4, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 5, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 6, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 7, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 8, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 9, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 }
  ];

  return (
    <>
    <Navbar /> 
    <div>
      <div className="row m-0 p-0 mt-3 mb-5 g-2">
        {products.map((productItem) => (
          <div className="col-6 col-md-3 card p-2 m-0" key={productItem.id}>
            <div className="text-center poa">
              <img src={product} className="img-fluid loaa" alt="Product" />
            </div>
            <div className="text-center koa">
              <div className="se1">{productItem.name}</div>
              <div className="se2">{productItem.desc}</div>
              <div className="se1">{productItem.weight}</div>
              <div className="row m-0 p-0">
                <div className="col p-0 m-0">
                  <div className="se1">{productItem.price}$</div>
                </div>
                <div className="col p-0 m-0">
                  <button className="adbtn">ADD</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <FooterMenu />
    </div>
    </>
  );
}

export default Shop;