import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faTag,
  faMobileAlt,
  faStar,
  faGift,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  return (
    <div>

 
      <nav className="navbar sticky-top  hide-on-desktop justify-content-center ">
        <div className="navbar-container">
          <div className="navbar-search">
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                placeholder="Search for atta, dal, coke and m..."
              />
            </div>
          </div>
        </div>
      </nav>

      <header className="hide-on-mobile sticky-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="top-header">
                <Link to="/" className="cr-logo">
                  <img
                    src="https://thediscountclub.in/admin/backend/logo/66bed037d1f84_tdctrylogo3.png"
                    alt="logo"
                    className="logo"
                  />
                </Link>
                <form class="cr-search">
                  <input
                    class="search-input"
                    type="text"
                    placeholder="Search For items..."
                  />
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected="">All Categories</option>
                    <option value="1">Pet Care</option>
                    <option value="2">Skin Care</option>
                    <option value="3">Health Care</option>
                  </select>
                  <a href="javascript:void(0)" class="search-btn">
                    <FontAwesomeIcon icon={faSearch} className="text-white" />
                  </a>
                </form>
                <div class="cr-right-bar">
                  <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle cr-right-bar-item"
                        href="javascript:void(0)"
                      >
                        <i class="bi bi-person h3 m-0"></i>
                        <span>Account</span>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/register">
                            Register
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/checkout">
                            Checkout
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/login">
                            Login
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#" class="cr-right-bar-item">
                    <i class="bi bi-heart h3 m-0"></i>
                    <span>Wishlist</span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="cr-right-bar-item Shopping-toggle"
                  >
                    <i class="bi bi-cart h3 m-0"></i>
                    <span>Cart</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cr-fix" id="cr-main-menu-desk">
          <div class="container-fluid">
            <div class="cr-menu-list">
              <div class="cr-category-icon-block">
                <div class="cr-category-menu">
                  <div class="cr-category-toggle">
                    <i class="bi bi-list d-flex m-0 h5 text-dark"></i>
                  </div>
                </div>
                <div class="cr-cat-dropdown">
                  <div class="cr-cat-block">
                    <div class="cr-cat-tab">
                      <div
                        class="cr-tab-list nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          class="nav-link active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          Dairy &amp; Bakery
                        </button>
                        <button
                          class="nav-link"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                          tabindex="-1"
                        >
                          Fruits &amp; Vegetable
                        </button>
                        <button
                          class="nav-link"
                          id="v-pills-messages-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                          tabindex="-1"
                        >
                          Snack &amp; Spice
                        </button>
                        <button
                          class="nav-link"
                          id="v-pills-settings-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-settings"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-settings"
                          aria-selected="false"
                          tabindex="-1"
                        >
                          Juice &amp; Drinks{" "}
                        </button>
                        <a
                          class="nav-link"
                          href="/"
                          aria-selected="false"
                          tabindex="-1"
                          role="tab"
                        >
                          View All{" "}
                        </a>
                      </div>
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <div class="tab-list row">
                            <div class="col">
                              <h6 class="cr-col-title">Dairy</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">Milk</a>
                                </li>
                              </ul>
                            </div>
                            <div class="col">
                              <h6 class="cr-col-title">Bakery</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Cake and Pastry
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                        >
                          <div class="tab-list row">
                            <div class="col">
                              <h6 class="cr-col-title">Fruits</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Cauliflower
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="col">
                              <h6 class="cr-col-title">Vegetable</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Cauliflower
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="v-pills-messages"
                          role="tabpanel"
                          aria-labelledby="v-pills-messages-tab"
                        >
                          <div class="tab-list row">
                            <div class="col">
                              <h6 class="cr-col-title">Snacks</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    French fries
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="col">
                              <h6 class="cr-col-title">Spice</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Cinnamon Powder
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="v-pills-settings"
                          role="tabpanel"
                          aria-labelledby="v-pills-settings-tab"
                        >
                          <div class="tab-list row">
                            <div class="col">
                              <h6 class="cr-col-title">Juice</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Mango Juice
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="col">
                              <h6 class="cr-col-title">soft drink</h6>
                              <ul class="cat-list">
                                <li>
                                  <a href="/">
                                    Breizh Cola
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav class="navbar navbar-expand-lg">
                <a href="javascript:void(0)" class="navbar-toggler shadow-none">
                  <i class="ri-menu-3-line"></i>
                </a>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link" href="javascript:void(0)">
                        About Us
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="/"
                          >
                            Pet Care
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/"
                          >
                            Skin Care
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/">
                            Health Care
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link" href="javascript:void(0)">
                        Shop
                      </a>
                    </li>

                    <li class="nav-item dropdown">
                      <a class="nav-link " href="javascript:void(0)">
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div class="cr-calling">
              <i class="bi bi-telephone h3 m-0"></i>
                <a href="javascript:void(0)">+91 63987 87737</a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
