import React from "react";
import product from "../Component/img/product.png";
import FooterMenu from "../Component/FooterMenu";
import Navbar from "../Component/Navbar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMobileAlt,
  faStar,
  faGift,
  faBaby,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

function Address() {
  return (
    <>
      <nav className="navbar sticky-top d-block">
        <div className="navbar-container">
          <div className="navbar-search">
            <div className="row">
              <div className="col">
              <div className="search-wrapper py-1 d-flex justify-content-between">
              <span className="spnn1">
                <FontAwesomeIcon icon={faArrowLeft} className="icnn1" /> My
                Profile
              </span>
              
            </div>
              </div>
              <div className="col d-flex justify-content-end">
              <span className="spnn2 mx-2">
                <i class="bi bi-basket d-flex m-0 h5 text-white"></i>
              </span>
              <span className="spnn2">
                <i class="bi bi-list d-flex m-0 h5 text-white"></i>
              </span>
              </div>
            </div>
            
          </div>
        </div>
      </nav>

      <div className="row m-0 p-0">
        <div class="col-lg-3 border-bottom bg-white">
          <div
            class="nav d-flex justify-content-between my-account-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              class="nav-link d-flex flex-column active"
              id="v-pills-my-address-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-my-address"
              type="button"
              role="tab"
              aria-controls="v-pills-my-address"
              aria-selected="true"
            >
              <i class="bi bi-map"></i>Address
            </button>
            <button
              class="nav-link d-flex flex-column"
              id="v-pills-my-order-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-my-order"
              type="button"
              role="tab"
              aria-controls="v-pills-my-order"
              aria-selected="false"
              tabindex="-1"
            >
              <i class="bi bi-list"></i>My Order
            </button>
            <button
              class="nav-link d-flex flex-column"
              id="v-pills-my-wallet-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-my-wallet"
              type="button"
              role="tab"
              aria-controls="v-pills-my-wallet"
              aria-selected="false"
              tabindex="-1"
            >
              <i class="bi bi-wallet"></i>My Wallet
            </button>
            <button
              class="nav-link d-flex flex-column"
              href="index.html"
              aria-selected="false"
              tabindex="-1"
              role="tab"
            >
              <i class="bi bi-key"></i>Logout
            </button>
          </div>
        </div>

        <div class="col-lg-9 bg-light text-start">
          <div class="tab-content p-3" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-my-address"
              role="tabpanel"
              tabindex="0"
              aria-labelledby="#v-pills-my-address-tab"
            >
              <div class="d-flex align-items-center justify-content-between w-100 mb-3">
                <h4 class="m-0">My Addresses</h4>
                <a
                  href="#"
                  class="btn btn-danger d-flex align-items-center rounded-pill text-link text-decoration-none btn-sm text-start"
                  data-bs-toggle="modal"
                  data-bs-target="#addaddress"
                >
                  <i class="bi bi-plus me-2"></i>Add New{" "}
                  <span class="d-none d-lg-block ms-1">Address</span>
                </a>
              </div>
              <div class="row row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-3 osahan-my-addresses">
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-house text-muted fs-5"></i>
                        <div class="pe-4 overflow-hidden">
                          <h6 class="fw-bold mb-1">Home</h6>
                          <p class="text-muted text-truncate mb-0 small">
                            H.No. 2834 Street, 784 Sector, Ludhiana, Punjab
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a
                        href="#"
                        class="small"
                        data-bs-toggle="modal"
                        data-bs-target="#addaddress"
                      >
                        <i class="bi bi-pencil fs-6"></i>
                        <br />
                        Edit
                      </a>
                      <a
                        href="#"
                        class="link-dark small"
                        data-bs-toggle="modal"
                        data-bs-target="#trash"
                      >
                        <i class="bi bi-trash fs-6"></i>
                        <br />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-house text-muted fs-5"></i>
                        <div class="pe-4 overflow-hidden">
                          <h6 class="fw-bold mb-1">Ludhiana</h6>
                          <p class="text-muted text-truncate mb-0 small">
                            87997 Street, 784 Sector, Ludhiana, Punjab
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a
                        href="#"
                        class="small"
                        data-bs-toggle="modal"
                        data-bs-target="#addaddress"
                      >
                        <i class="bi bi-pencil fs-6"></i>
                        <br />
                        Edit
                      </a>
                      <a
                        href="#"
                        class="link-dark small"
                        data-bs-toggle="modal"
                        data-bs-target="#trash"
                      >
                        <i class="bi bi-trash fs-6"></i>
                        <br />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-briefcase text-muted fs-5"></i>
                        <div class="pe-4 overflow-hidden">
                          <h6 class="fw-bold mb-1">Office</h6>
                          <p class="text-muted text-truncate mb-0 small">
                            9878, 784 Sector, Ludhiana, Punjab
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a
                        href="#"
                        class="small"
                        data-bs-toggle="modal"
                        data-bs-target="#addaddress"
                      >
                        <i class="bi bi-pencil fs-6"></i>
                        <br />
                        Edit
                      </a>
                      <a
                        href="#"
                        class="link-dark small"
                        data-bs-toggle="modal"
                        data-bs-target="#trash"
                      >
                        <i class="bi bi-trash fs-6"></i>
                        <br />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-house text-muted fs-5"></i>
                        <div class="pe-4 overflow-hidden">
                          <h6 class="fw-bold mb-1">Home</h6>
                          <p class="text-muted text-truncate mb-0 small">
                            H.No. 2834 Street, 784 Sector, Ludhiana, Punjab
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a
                        href="#"
                        class="small"
                        data-bs-toggle="modal"
                        data-bs-target="#addaddress"
                      >
                        <i class="bi bi-pencil fs-6"></i>
                        <br />
                        Edit
                      </a>
                      <a
                        href="#"
                        class="link-dark small"
                        data-bs-toggle="modal"
                        data-bs-target="#trash"
                      >
                        <i class="bi bi-trash fs-6"></i>
                        <br />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="v-pills-my-order"
              role="tabpanel"
              tabindex="0"
              aria-labelledby="#v-pills-my-order-tab"
            >
              <div class="d-flex align-items-center justify-content-between w-100 mb-3">
                <h4 class="m-0">My Orders</h4>
              </div>
              <div class="row row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-3 osahan-my-orders">
                <div class="col">
                  <div
                    data-bs-toggle="offcanvas"
                    data-bs-target="#viewdetails"
                    aria-controls="viewdetails"
                    class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3"
                  >
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-basket text-success fs-4"></i>
                        <div>
                          <small class="badge bg-success-subtle text-success rounded-pill fw-normal small-sm mb-2">
                            Delivered
                          </small>
                          <h6 class="fw-bold mb-1 d-flex align-items-center">
                            ORD049190212 ·{" "}
                            <span class="text-danger fw-normal ms-2">
                              {" "}
                              $186
                            </span>{" "}
                          </h6>
                          <p class="text-muted text-truncate mb-0 small">
                            Placed on wed, 19 Oct 23, 12:55 pm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a href="#" class="small">
                        <i class="bi bi-eye fs-6"></i>
                        <br />
                        View Details
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div
                    data-bs-toggle="offcanvas"
                    data-bs-target="#viewdetails"
                    aria-controls="viewdetails"
                    class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3"
                  >
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-basket text-success fs-4"></i>
                        <div>
                          <small class="badge bg-success-subtle text-success rounded-pill fw-normal small-sm mb-2">
                            Delivered
                          </small>
                          <h6 class="fw-bold mb-1 d-flex align-items-center">
                            ORD065763273 ·{" "}
                            <span class="text-danger fw-normal ms-2">
                              {" "}
                              $876
                            </span>{" "}
                          </h6>
                          <p class="text-muted text-truncate mb-0 small">
                            Placed on wed, 22 oct'22, 12:55 pm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a href="#" class="small">
                        <i class="bi bi-eye fs-6"></i>
                        <br />
                        View Details
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div
                    data-bs-toggle="offcanvas"
                    data-bs-target="#viewdetails"
                    aria-controls="viewdetails"
                    class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3"
                  >
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-basket text-success fs-4"></i>
                        <div>
                          <small class="badge bg-danger-subtle text-danger rounded-pill fw-normal small-sm mb-2">
                            Cancelled
                          </small>
                          <h6 class="fw-bold mb-1 d-flex align-items-center">
                            ORD065763273 ·{" "}
                            <span class="text-danger fw-normal ms-2">
                              {" "}
                              $976
                            </span>{" "}
                          </h6>
                          <p class="text-muted text-truncate mb-0 small">
                            Placed on wed, 22 oct'22, 12:55 pm
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <a href="#" class="small">
                        <i class="bi bi-eye fs-6"></i>
                        <br />
                        View Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="v-pills-my-wallet"
              role="tabpanel"
              tabindex="0"
              aria-labelledby="#v-pills-my-wallet-tab"
            >
              <div class="d-flex align-items-center justify-content-between w-100 mb-3">
                <h4 class="m-0">My Balance</h4>
                <h4 class="fw-bold text-danger m-0">$75</h4>
              </div>
              <div class="row row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-3">
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-plus-circle text-muted fs-5"></i>
                        <div class="lh-sm">
                          <h6 class="fw-bold text-success mb-1">Cashback</h6>
                          <p class="text-truncate mb-2 small">
                            Transaction ID: 50919487
                          </p>
                          <small class="text-muted">
                            On 23 Oct 18, 03:13 PM
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <span class="text-success fw-bold h6 m-0">+$75</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi bi-dash-circle text-muted fs-5"></i>
                        <div class="lh-sm">
                          <h6 class="fw-bold text-danger mb-1">Purchase</h6>
                          <p class="text-truncate mb-2 small">
                            Transaction ID: 50919487
                          </p>
                          <small class="text-muted">
                            On 23 Oct 18, 03:13 PM
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <span class="text-danger fw-bold h6 m-0">-$75</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center justify-content-between bg-white border p-3 rounded-3">
                    <div class="w-75">
                      <div class="d-flex align-items-center gap-3 osahan-mb-1">
                        <i class="bi-plus-circle text-muted fs-5"></i>
                        <div class="lh-sm">
                          <h6 class="fw-bold text-success mb-1">Cashback</h6>
                          <p class="text-truncate mb-2 small">
                            Transaction ID: 50919487
                          </p>
                          <small class="text-muted">
                            On 23 Oct 18, 03:13 PM
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto d-flex align-items-center gap-3 text-center small">
                      <span class="text-success fw-bold h6 m-0">+$75</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <FooterMenu />
      </div>
    </>
  );
}

export default Address;
