import React from 'react';

function Checkout() {
  return (
    <div>
      <h1>Checkout Page</h1>
      <p>Proceed to checkout.</p>
    </div>
  );
}

export default Checkout;
