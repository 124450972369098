import React from "react";
import FooterMenu from "../Component/FooterMenu";
import Navbar from "../Component/Navbar";
import BrandSlider from "../Component/BrandSlider";
import Footer from "../Component/Footer";
import DeliverySection from "../Component/DeliverySection";
import one from "../Component/img/1.webp";
import two from "../Component/img/2.webp";
import three from "../Component/img/3.webp";
import four from "../Component/img/4.webp";
import five from "../Component/img/5.webp";
import six from "../Component/img/6.webp";
import seven from "../Component/img/7.webp";
import eight from "../Component/img/8.webp";

function Home() {
  // Array of category data
  const categories = [
    "Vegetables & Fruits",
    "Dairy Products",
    "Beverages",
    "Snacks",
    "Bakery",
    "Meat & Seafood",
    "Frozen Foods",
    "Pantry Staples"
  ];

  const productImages = [one, two, three, four, five, six, seven, eight];

  return (
    <>
      <Navbar />
      <FooterMenu />
      
      
      <div className="container-fluid">
        <section className="mt-1">
          <div id="fruitCarousel" className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row g-3 text-start">
                  <div className="col-lg-12 col-12">
                    <div className="grocery-banner-1 rounded-4 ">
                      <h4 className="fw-bold mb-1">Fresh <span className="fw-light text-black">Fruits</span></h4>
                      <p>Get Upto <span className="fw-bold">30%</span> Off</p>
                      <a href="listing.html" className="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i className="bi bi-arrow-right ms-1"></i></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="row g-3 text-start">
                  <div className="col-lg-12 col-12">
                    <div className="grocery-banner-1 rounded-4">
                      <h4 className="fw-bold mb-1">Fresh <span className="fw-light text-black">Fruits</span></h4>
                      <p>Get Upto <span className="fw-bold">30%</span> Off</p>
                      <a href="listing.html" className="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i className="bi bi-arrow-right ms-1"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#fruitCarousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#fruitCarousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>

<div className="paddmob">
<div className="row">
          <div className="col-8">
            <h1 className="h11 text-start mt-3">Shop Popular Categories</h1>
          </div>
          <div className="col-4">
            <h1 className="h112 text-end mt-3">View More</h1>
          </div>
        </div>

        <div className="row m-0 p-0">
          {categories.map((category, index) => (
            <div className="col-md-3 col-3 m-0 p-0" key={index}>
              <div className="jha">
                <div className="bg-pd">
                  <img src={productImages[index % productImages.length]} className="img-fluid imh1" alt={category} />
                </div>
                <div className="pg-txt mt-1">{category}</div>
              </div>
            </div>
          ))}
        </div>

        <section className="mt-3">
          <div className="row g-3 text-start">
            <div className="col-lg-6 col-12">
              <div className="grocery-banner-1 rounded-4">
                <h4 className="fw-bold mb-1">Fresh <span className="fw-light text-black">Fruits</span></h4>
                <p>Get Upto <span className="fw-bold">30%</span> Off</p>
                <a href="listing.html" className="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i className="bi bi-arrow-right ms-1"></i></a>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className="grocery-banner-1 rounded-4">
                <h4 className="fw-bold mb-1">Fresh <span className="fw-light text-black">Fruits</span></h4>
                <p>Get Upto <span className="fw-bold">30%</span> Off</p>
                <a href="listing.html" className="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i className="bi bi-arrow-right ms-1"></i></a>
              </div>
            </div>
          </div>
        </section>

        {["Category One", "Category Two", "Category Three", "Brands"].map((categoryTitle, index) => (
          <div key={index}>
            <div className="row">
              <div className="col-8">
                <h1 className="h11 text-start mt-3">{categoryTitle}</h1>
              </div>
              <div className="col-4">
                <h1 className="h112 text-end mt-3">View More</h1>
              </div>
            </div>

            <div className="row m-0 p-0">
              {categories.map((category, idx) => (
                <div className="col-md-3 col-4 m-0 p-0" key={idx}>
                  <div className="jha">
                    <div className="bg-pd">
                      <img src={productImages[(index + idx) % productImages.length]} className="img-fluid imh1" alt={category} />
                    </div>
                    <div className="pg-txt mt-1">{category}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}


</div>

<DeliverySection />     
<BrandSlider />
        

       

        <Footer />
        <FooterMenu />
      </div>
    </>
  );
}

export default Home;
