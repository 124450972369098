import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Basic Swiper styles
import 'swiper/css/navigation';  // Optional: Navigation styles
import 'swiper/css/pagination';  // Optional: Pagination styles

// Import the brand images
import one from "../Component/img/1.webp";
import two from "../Component/img/2.webp";
import three from "../Component/img/3.webp";
import four from "../Component/img/4.webp";
import five from "../Component/img/5.webp";
import six from "../Component/img/6.webp";
import seven from "../Component/img/7.webp";
import eight from "../Component/img/8.webp";

const BrandSlider = () => {
  const swiperRef = useRef(null); // Reference to the Swiper instance

  // Array of brand images
  const brandImages = [one, two, three, four, five, six, seven, eight];

  // Custom autoplay functionality using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      if (swiperRef.current && swiperRef.current.slideNext) {
        swiperRef.current.slideNext(); // Move to the next slide
      }
    }, 2000); // 2 seconds delay

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="container-fluid my-4 bg-brand">
      <div className="row">
        <div className="col-8">
          <h1 className="h11 text-start mt-3">Our Brands</h1>
        </div>
        <div className="col-4">
          <h1 className="h112 text-end mt-3">View More</h1>
        </div>
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView={5}
        loop={true}  // Enables infinite looping of slides
        onSwiper={(swiper) => {
          swiperRef.current = swiper; // Assign the Swiper instance to the ref
        }}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 5 }
        }}
      >
        {brandImages.map((brand, index) => (
          <SwiperSlide key={index}>
            <div className="brand-slide jha">
              <img src={brand} alt={`Brand ${index + 1}`} className="img-fluid ht200" />
              <div className="pg-txt mt-1">Name</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BrandSlider;
